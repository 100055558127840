
export default function orderCareerByCategory(allCareers) {

  // Sort careers if there are any
  if (allCareers.length > 0) {
    allCareers.sort((a, b) => {
      // Ordenando por posgrado
      const posgradoOrder = {
        'Doctorados': 1,
        'Maestrías': 2,
        'Especializaciones': 3,
        'Otro': 4,
      }

      const getPosgradoLevel = (careerCategory) => {
        if (careerCategory.name === 'Doctorados') return 'Doctorados'
        if (careerCategory.name === 'Maestrías') return 'Maestrías'
        if (careerCategory.name === 'Especializaciones') return 'Especializaciones'
        return 'Otro'
      }

      const levelA = getPosgradoLevel(a.career_category)
      const levelB = getPosgradoLevel(b.career_category)
    
      // Si los niveles de posgrado son diferentes, ordena por nivel de posgrado
      if (posgradoOrder[levelA] !== posgradoOrder[levelB]) {
        return posgradoOrder[levelA] - posgradoOrder[levelB]
      }

      // Si los niveles de posgrado son iguales, ordena alfabéticamente
      return a.name.localeCompare(b.name)
    })
  }
}
