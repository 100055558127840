import orderCareerByCategory from "./orderCareerByCategory"

export const matchOrganizationByLevel = (data) => {
  const {
    allStrapiCareerCategory: { nodes: categoriesIds },
    allStrapiOrganizationalUnit: { nodes: organizationsAll },
    allStrapiUccCareer: { nodes: careersAll },
    allStrapiIcdaCareer: { nodes: icdaCarrers }
  } = data

  const organizationsWithCareer = []

  organizationsAll.forEach((organization) => {
    // Creating organization
    const organizationToPush = {
      name: organization.name,
      color: organization.color,
      coverImg:
        organization.header && organization.header.slide.length > 0
          ? organization.header.slide[0].backgroundImage
          : null,
      careers: [],
      slug: organization.slug
    }

    // Creating carrer of Organization
    organization.ucc_careers.forEach((career) => {
      // Macheando la Unidad Organizacional con la carrera
      careersAll.forEach((careerItem) => {
        if(career.id === careerItem.strapiId) {
          // Validating category of career
          categoriesIds.forEach((category) => {
            if (careerItem.career_category.id === category.strapiId) {
              // Guardo los datos de la carrera que viene de StrapiUccCareers
              organizationToPush.careers.push(careerItem)
            }
          })
        }
      })
    })

    if (organizationToPush.careers.length > 0)
      organizationsWithCareer.push(organizationToPush)
    
      // Sort careers by category
      orderCareerByCategory(organizationToPush.careers)
  })

  // Carreras ICDA - Pregrados
  const icdaToPush = {
    name: "ICDA - Escuela de Negocios",
    color: "#1d317d",
    coverImg: icdaCarrers[0].main.headerImages.image,
    careers: [],
    slug: "https://icda.ucc.edu.ar/"
  }
  icdaCarrers.forEach((career) => { 
    categoriesIds.forEach((category) => {
      if (career.career_category.name === category.name) {
        icdaToPush.careers.push(career)
      }
    })
  })
  if (icdaToPush.careers.length > 0)
    organizationsWithCareer.push(icdaToPush)

  return organizationsWithCareer
}
