import { Link, graphql } from 'gatsby'
import React, { useState, useLayoutEffect } from 'react'
// import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faLaptop, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import Seo from '../../components/seo'
import Layout from '../../components/layout'
import Carousels from '../../components/carousel'
import { useForm } from '../../hooks/useForm'
import coverFeeds from '../../helpers/carrerCoverFeeds'
import { searchCareer } from '../../helpers/searchCareer'
import { matchOrganizationByLevel } from '../../helpers/matchOrganizationByLevel'
import { CAREER_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'

const Career = ({ data, pageContext }) => {
  const [ organizationsByCareers, setOrganizationsByCareers ] = useState([])

  // Filter values
  const [ careerLevel, setCareerLevel ] = useState('')
  const [ uccCareerPlacesFilter, setUccCareerPlacesFilter ] = useState('')
  const [ uccCareerModalityFilter, setUccCareerModalityFilter ] = useState('')
  const [ valuesFilter, handleInputChange ] = useForm({inputSearch: ''})
  const { inputSearch } = valuesFilter

  const { mainNavbar, careerLevelName, filterVirtualCareers, filterPlacesCareer } = pageContext

  const {
    strapiInstitution: institution,
    allStrapiCareerCategory: careerCategories,
    allStrapiOrganizationalUnit: organizationalUnits,
    allStrapiUccCareer: uccCareers,
    allStrapiUccCareerPlaces: uccCareerPlaces,
  } = data

  // console.log("datas");
  // console.log(careerCategories);
  // console.log(uccCareers);
  // console.log(organizationalUnits);
  // console.log(uccCareerPlaces);
  // console.log("END ---- datas");

  const objCarousel = {
    visible: true,
    slide: coverFeeds(careerLevelName, institution)
  }

  const page = {
    slug: null,
    ...mainNavbar
  }

  // Busco la relacion de OU con las carreras
  useLayoutEffect(() => {
    if (filterPlacesCareer) {
      setUccCareerPlacesFilter(filterPlacesCareer)
    }

    setOrganizationsByCareers(matchOrganizationByLevel(data))
  }, [data])
  
  let pageTitle = careerLevelName
    ? `Carreras ${careerLevelName}`
    : 'Propuesta académica'

  const categories = careerCategories.nodes

  const careerLevels = [
    ...new Set(categories.map((category) => category.career_level.name))
  ]

  // Borrando los filtros
  const handleShowAllClick = () => {
    setCareerLevel('')
    setUccCareerPlacesFilter('')
    setUccCareerModalityFilter('')
  }

  const isButtonActive = (level) => careerLevel === level
  const isPlaceButtonActive = (place) => uccCareerPlacesFilter.title === place.title
  const isModalityButtonActive = (modality) => uccCareerModalityFilter === modality

  // Header Text
  let headerText;
  if (careerLevelName) {
    headerText = (
      <h5 className="text-uppercase title-secondary font-weight-bold">
        Carreras de{' '}
        <span className="font-weight-bold text-main">
          {careerLevelName}
        </span>
      </h5>
    );
  } else if (filterPlacesCareer) {
    headerText = (
      <h5 className="text-uppercase title-secondary font-weight-bold">
        Carreras en{' '}
        <span className="font-weight-bold text-main">
          {filterPlacesCareer.title}
        </span>
      </h5>
    );
  } else if (filterVirtualCareers) {
    headerText = (
      <h5 className="text-uppercase title-secondary font-weight-bold">
        Carreras{' '}
        <span className="font-weight-bold text-main">
          {'a distancia'}
        </span>
      </h5>
    );
  } else {
    headerText = (
      <h5 className="text-uppercase title-secondary font-weight-bold">
        {'Propuesta académica'}
      </h5>
    );
  }

  return (
    <div>
      <Layout page={page} logo={page.logo} header={page.header}>
        <Seo title={pageTitle} />

        <section className="schedule-feed mb-3">
          {objCarousel?.slide ? (
            <div className="d-flex flex-column schedule-header-carousel">
              <Carousels carousel={objCarousel} keyName={'scheduleFeed-'} />
            </div>
          ) : null}

          <div className="container">
            <div className="my-5">
              <div className="d-flex align-items-center justify-content-center row mb-2">
                <div className="col-12 col-md-8 mb-auto">
                  {headerText}
                  <hr className="my-2" style={{ backgroundColor: '#f7f7f7' }} />
                </div>

                <form className="col-12 col-md-4 pb-1">
                  <div className="d-flex feed-form">
                    <div className="input-group">
                      <div className="input-group-prepend ">
                        <span
                          className=" form-control form-control-sm input-group-text"
                          id="basic-addon1"
                        >
                          <FontAwesomeIcon icon={faSearch} size="sm" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Buscar..."
                        aria-label="Buscar..."
                        aria-describedby="basic-addon1"
                        name="inputSearch"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              
              {/* Filter Buttons */}
              <div className="row col-12 mb-3 align-items-center">
                {careerLevels.map((careerLevel, index) => {
                  if (careerLevel !== '' && careerLevel !== null) {
                    return (
                      <button
                        key={`careerlink-${index}`}
                        className={`btn-outline-darker ${isButtonActive(careerLevel) ? 'active' : ''}`}
                        onClick={() => !isButtonActive(careerLevel) ? setCareerLevel(careerLevel) : setCareerLevel('')}
                      >
                        {careerLevel}
                      </button>
                    )
                  }
                  return null
                })}

                {/* Vertical Line */}
                <div className="vertical-line"></div>

                {uccCareerPlaces.nodes.map((place, index) => {
                  if (place && place !== null) {
                    return (
                      <button
                        key={`careerlink-${index}`}
                        className={`btn-outline-darker ${isPlaceButtonActive(place) ? 'active' : ''}`}
                        onClick={() => !isPlaceButtonActive(place) ? setUccCareerPlacesFilter(place) : setUccCareerPlacesFilter('')}
                      >
                        {place.title}
                        {/* <FontAwesomeIcon
                          icon={place.title === "A Distancia" ? faLaptop : faLocationDot}
                          className="ml-2"
                          color={place.colorHex}
                        /> */}
                      </button>
                    )
                  }
                  return null
                })}

                {/* Vertical Line */}
                <div className="vertical-line"></div>

                {uccCareers.nodes.some(career => career.modality === "Distancia" || career.modality === "Ambas") && ( 
                  <button
                    key={`careerlink-Distancia`}
                    className={`btn-outline-darker text-capitalize ${isModalityButtonActive("Distancia") ? 'active' : ''}`}
                    onClick={() => !isModalityButtonActive("Distancia") ? setUccCareerModalityFilter("Distancia") : setUccCareerModalityFilter('')}
                  >
                    A Distancia
                  </button>
                )}

                {/* Vertical Line */}
                <div className="vertical-line"></div>

                <button
                  className="btn-outline-darker"
                  onClick={handleShowAllClick}
                >
                  Ver todas
                </button>
              </div>

              <div className=" row d-flex align-items-stretch">
                {organizationsByCareers.length == 0 ? (
                  <h4 className="mt-5 col">
                    No se encontraron carreras de {careerLevelName} disponibles
                  </h4>
                ) : (
                  searchCareer(
                    organizationsByCareers,
                    inputSearch,
                    categories,
                    careerLevel,
                    uccCareerPlacesFilter,
                    uccCareerModalityFilter
                  ).map((organization) => {
                    return feedCarrerCreator(
                      organization,
                      categories,
                      uccCareerPlaces.nodes
                    )
                  })
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

// // Iconos de ubicacion y a distancia para las carreras
// const getCareerIcon = (career, uccCareerPlaces) => {
//   if (career.ucc_career_places) {
//     return (
//       // Observamos los lugares donde se dicta la carrera
//       career.ucc_career_places.map((place) => {
//         return (
//           // Macheamos con los lugares registrados
//           uccCareerPlaces.map((places, index) => {
//             if (place.title === places.title && place.title !== "A Distancia") {
//               return (
//                 <div key={`tooltip-${index}`}>
//                   <FontAwesomeIcon
//                     icon={faLocationDot}
//                     className="ml-2"
//                     color={places.colorHex}
//                     data-tooltip-id={`tooltip-${index}`}
//                     data-tooltip-content={places.title}
//                   />
//                   <ReactTooltip id={`tooltip-${index}`} place="top" effect="solid" style={{zIndex: 500}}/>
//                 </div>
//               )
//             } else if (place.title === places.title && place.title === "A Distancia") {
//               return (
//                 <div key={`tooltip-${index}`}>
//                   <FontAwesomeIcon
//                     icon={faLaptop}
//                     className="ml-2"
//                     color={places.colorHex}
//                     data-tooltip-id={`tooltip-${index}`}
//                     data-tooltip-content={places.title}
//                   />
//                   <ReactTooltip id={`tooltip-${index}`} place="top" effect="solid"/>
//                 </div>
//               )
//             }
//           })
//         )
//       })
//     )
//   } 
// }

const generateCareerLink = (organization, career, uccCareerPlaces) => {
  const baseUrl =
    organization.name === 'ICDA - Escuela de Negocios'
      ? `https://icda.ucc.edu.ar/${CAREER_GENERAL_ROUTE}/${career.slug}`
      : `/${CAREER_GENERAL_ROUTE}/${career.slug}`

  const target = organization.name === 'ICDA - Escuela de Negocios' ? '_blank' : '_self'

  return (
    <Link
      to={baseUrl}
      className="list-group-item cursor-pointer fade-in-image text-decoration-none"
      key={`career-key-${career.name}`}
      target={target}
    >
      {career.name} 
      {/* <div className='d-flex'>
        {getCareerIcon(career, uccCareerPlaces)}
      </div> */}
    </Link>
  )
}

// Building Feed Propuesta Academica
const feedCarrerCreator = (organization, categories, uccCareerPlaces) => {

  var espCsSalud = false
  
  return organization.careers.length > 0 ? (
    <div className="mb-4 col-12 col-md-6 col-lg-4" key={`key-feed-career-${organization.name}`}>
      <div className="card h-100 shadow-sm fade-in-image">
        {/* Portada de OU o ICDA */}
        <Link
          to={
            organization.name === 'ICDA - Escuela de Negocios'
              ? `${organization.slug}`
              : `/${organization.slug}`
          }
          target="_blank"
          className="disable__link__styles"
        >
          <div
            className="w-100 news-image card-img-top d-flex flex-column align-items-end cursor-pointer "
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${
                organization.coverImg
                  ? organization.coverImg.url
                  : 'https://images.pexels.com/photos/159490/yale-university-landscape-universities-schools-159490.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
              }")`
            }}
          >
            <div className="card-body d-flex align-items-end align-self-start">
              <h4 className="card-title font-weight-bold  text-white text-uppercase">
                {organization.name}
              </h4>
            </div>
            <div
              className="w-100"
              style={{
                backgroundColor: organization.color,
                height: '8px'
              }}
            ></div>
          </div>
        </Link>
        {/* Listando carreras */}
        <ul className="list-group list-group-flush">
          {organization.careers
            .map((career) => {
              const category = categories.find((cat) => cat.strapiId === career.career_category.id);
              
              // exclude health specialties careers from academic offer
              if (category?.name === 'Especialidad Cs Salud' && espCsSalud === false) {
                espCsSalud = true

                const carrerCsSalud = {
                  name: "Especialidades Médicas",
                  slug: "../propuesta-academica/especialidades-cs-salud"
                }
                return generateCareerLink(organization, carrerCsSalud, uccCareerPlaces);
              } else if (category?.name !== 'Especialidad Cs Salud') {
                return generateCareerLink(organization, career, uccCareerPlaces);
              }
            })
          }
        </ul>
      </div>
    </div>
  ) : null
}

export default Career

export const allCareerQuery = graphql`
  query($careerlevelId: Int) {
    allStrapiIcdaCareer {
      nodes {
        name
        slug
        main {
          headerImages {
            image {
              url
            }
          }
        }
        career_category {
          name
        }
      }
    }
    allStrapiCareerCategory(
      filter: { career_level: { id: { eq: $careerlevelId } } }
    ) {
      nodes {
        strapiId
        name
        career_level {
          id
          name
        }
      }
    }
    allStrapiOrganizationalUnit {
      nodes {
        id
        name
        slug
        color
        ucc_careers {
          id
        }
        header {
          slide {
            backgroundImage {
              url
            }
          }
        }
      }
    }
    allStrapiUccCareer {
      nodes {
        strapiId
        name
        slug
        modality
        career_category {
          id
          name
        }
        organizational_unit {
          id
        }
        ucc_career_places {
          title
        }
      }
    }
    allStrapiUccCareerPlaces {
      nodes {
        strapiId
        title
        colorHex
      }
    }
    strapiInstitution {
      careersCovers {
        gradoCareerCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        posgradoCareerCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        pregradoCareerCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`

Career.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object
}
