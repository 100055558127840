export const searchCareer = (organizationsByCareers, inputSearch, careerCategories, careerLevelFilter, careerPlacesFilter, careerModalityFilter) => {
  // console.log("Filters --------------------------");
  // console.log(organizationsByCareers);
  // console.log(careerCategories);
  // console.log(careerLevelFilter);
  // console.log(careerPlacesFilter);
  // console.log(careerModalityFilter);
  // console.log("Filters --------------------------");


  return organizationsByCareers
    .map((organization) => ({
      name: organization.name,
      coverImg: organization.coverImg,
      color: organization.color,
      slug: organization.slug,
      careers: organization.name === "ICDA - Escuela de Negocios" 
        ? organization.careers 
          .filter((career) => { // Filter by Places
            if (careerPlacesFilter && careerPlacesFilter.title == "UCC Río Cuarto") {
              return false
            }
            return true
          })
        : organization.careers
          .filter((career) => { // Filter by category
            if (careerLevelFilter) {
              const matchingCategory = careerCategories.find(
                category =>
                  category.strapiId === career.career_category.id &&
                  category.career_level.name === careerLevelFilter
              )
              return matchingCategory !== undefined
            }
            return true
          })
          .filter((career) => { // Filter by Places
            if (careerPlacesFilter) {
              const matchingPlaces = career.ucc_career_places?.find(place =>
                careerPlacesFilter.title === place.title
              );
              return matchingPlaces !== undefined
            }
            return true
          })
          .filter((career) => { // Filter by Virtual Modality
            if (careerModalityFilter) {
              const matchingModality = careerModalityFilter === career.modality || career.modality === "Ambas"
              return matchingModality !== false
            } 
            return true          
          })
          .filter((career) => // Filter by search
            career.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
              .match(
                inputSearch
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/\p{Diacritic}/gu, '')
              ) !== null
          )
    }))
    .sort(function (a, b) {
      return a.name.localeCompare(b.name)
    })
}
