import { GRADO, POSGRADO, PREGRADO } from '../utils/variables/careerLevels'

const coverFeeds = (levelName, institution) => {
  switch (levelName?.toLowerCase()) {
    case GRADO:
      return institution.careersCovers?.gradoCareerCover
    case POSGRADO:
      return institution.careersCovers?.posgradoCareerCover
    case PREGRADO:
      return institution.careersCovers?.pregradoCareerCover
    default:
      return null
  }
}

export default coverFeeds
